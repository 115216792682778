<template>
    <div id="app" class="min-h-screen flex items-center">
        <div class="max-w-2xl w-full mx-auto p-6">
            <img
                alt="Muscatis Ltd"
                src="./assets/logo.svg"
                class="h-10 w-auto mb-12"
            />
            <h1 class="text-6xl font-black text-gray-700 mb-4">
                Web application <br />development
            </h1>
        </div>
    </div>
</template>

<script>
export default {
    name: "App",
    mounted() {
      let kch = document.createElement('script')
      kch.setAttribute('src', 'https://kch.muscatis.uk/dist/attributions.js?id=8e81a7335871b9342a960bc105477cnt')
      kch.setAttribute('async', '')
      document.head.appendChild(kch)
    },

};
</script>
